<template>
  <main id="login-view">
    <div class="card__header">
      <div class="card__header-text">
        <h4 class="card__title">{{ $t('signin') }}</h4>
      </div>
    </div>
    <template v-if="recoverPasswordSent">
      <div class="card card--with-header">
        <div class="card__content">
            <p>{{ $t('password-reset-confirm') }}</p>
        </div>
      </div>
    </template>
    <template v-else>
    <div class="card card--with-header">
      <div class="card__content">
        <div class="card__header">
          <h4 class="card__title">{{ $t('signin_subtitle') }}</h4>
        </div>
        <form action="#signin" class="form" v-on:submit="onSubmit">
          <form-field v-for="item in formFields" :key="item.name" :ref="item.name" :fielddata="item" v-on:change="onFieldChange"></form-field>
        </form>
        <div class="modal__actions">
          <div class="modal__actions--left">
            <button type="button" class="btn btn--text" @click="recoverPassword">{{ $t('recover-password') }}</button>
          </div>
          <div class="modal__actions--right">
            <button type="button" class="btn btn--new" @click="onSubmit">{{ $t('submit') }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__actions">
      <div class="modal__actions--left">
        &nbsp;
      </div>
      <div class="modal__actions--right">
        <router-link to="/signup" class="btn btn--new-secondary">{{ $t('sign-up') }}</router-link>
      </div>
    </div>
    </template>
  </main>
</template>
<script>
import {
  mapGetters,
  mapActions
} from 'vuex'
import FormField from '@/core/components/FormField.vue'
import {
  SIGN_IN,
  RESET_PASSWORD,
  SHOW_NOTIFICATION
} from '@/core/action-types'

export default {
  name: 'signin',
  components: {
    FormField
  },
  data: function () {
    return {
      recoverPasswordSent: false,
      formValues: {
        username: '',
        password: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'authData'
    ]),
    // This array builds form's fields
    formFields: function () {
      return [
        {
          name: 'username',
          type: 'email',
          label: `${this.$t('username')} *`,
          title: this.$t('username'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null
        },
        {
          name: 'password',
          type: 'password',
          label: `${this.$t('password')} *`,
          title: this.$t('password'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null
        }
      ]
    }
  },
  mounted () {
    if (this.authData === null && localStorage.getItem('access_token')) {
      this.$router.push({ path: '/signin' })
    }
  },
  methods: {
    ...mapActions({
      signIn: SIGN_IN,
      resetPassword: RESET_PASSWORD,
      showNotification: SHOW_NOTIFICATION
    }),
    recoverPassword () {
      const usernameField = this.$refs['username'][0].$el.firstChild

      if (usernameField.checkValidity()) {
        const onComplete = function (responseData) {
          if (responseData.data?.success) {
            this.recoverPasswordSent = true
          } else {
            this.showNotification({ text: responseData.error.message, type: 'error', icon: 'error' })
          }
        }
        this.resetPassword({
          email: this.formValues.username,
          onComplete: onComplete.bind(this)
        })
      } else {
        usernameField.reportValidity()
      }
    },
    onFieldChange (data) {
      this.formValues[data.field] = data.value
    },
    onSubmit () {
      this.signIn({
        email: this.formValues.username,
        password: this.formValues.password
      })
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<i18n>
{
  "en": {
    "signin": "Sign In",
    "signin_subtitle": "Enter your user and password"
  },
  "es": {
    "signin": "Identificación",
    "signin_subtitle": "Introduce tu usuario y contraseña"
  }
}
</i18n>
<style scoped>
#login-view {
  margin: 0 auto;
  width: 600px;
}
.card {
  display: block;
}
.card__content {
  padding: 20px;
}
.form {
  padding: 10px;
}
.form__field {
  flex-basis: calc(50% - 12px);
}
</style>
